.top_btns[data-v-614ff2de] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-614ff2de] {
  font-size: 13px;
  cursor: pointer;
}
.item_icon .iconfont[data-v-614ff2de] {
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .icon-wenhao[data-v-614ff2de] {
  color: red;
  margin-left: 3px;
}